import React from "react";
import PropTypes from "prop-types";
import { Row, Col, Typography } from "antd";
import Heading from "../../components-v2/Base/HeadingBuilder";
import CtaPrimary from "../Buttons/primary-cta";
import Image from "../image";

const { Title } = Typography;

const LargeCta = (props) => {
  const { sectionData, imgClassName, style } = props;

  return (
    <section className="cta cta-large-banner">
      <Row className="cta-large" align="middle" style={style}>
        <Col lg={12} md={24} sm={24} xs={24}>
          <div style={{ padding: "0 32px" }}>
            <Heading
              level={2}
              injectionType={2}
              className="text-white mt-8 lg:mt-0"
            >
              {sectionData.title}
            </Heading>
            {sectionData.subtext && <p>{sectionData.subtext}</p>}
            <CtaPrimary
              ctaTitle={sectionData.ctaInfo.ctaTitle}
              target={sectionData.ctaInfo.ctaTarget}
            />
          </div>
        </Col>
        <Col lg={12} md={24} sm={24} xs={24}>
          <Image
            imageName={sectionData?.ctaImg}
            className={`${imgClassName && imgClassName} cta-img`}
          />
        </Col>
      </Row>
    </section>
  );
};

LargeCta.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.any),
  imgClassName: PropTypes.string,
};
LargeCta.defaultProps = {
  sectionData: {},
  style: {},
  imgClassName: "",
};

export default LargeCta;
